(function ($) {
  function _setSmooshColor(skuId, $product) {
    var $smoosh = $('.js-product-smoosh', $product);
    var sku;

    if (skuId) {
      sku = prodcat.data.getSku(skuId);
      if (sku && sku.HEX_VALUE_STRING) {
        $smoosh.css('background-color', sku.HEX_VALUE_STRING);
      }
    }
  }

  $(document).on('click', '.js-product-shade', function (event) {
    event.preventDefault();
    var skuId = $(this).data('sku');

    if (skuId) {
      var sku = prodcat.data.getSku(skuId);
      var skuBaseId;

      if (sku) {
        skuBaseId = sku.SKU_BASE_ID;
        var $product = $(this).closest('.js-product');
        var $shade_picker = $(this).closest('.js-product-shade-picker');
        // is this a travel kit shader?
        var kit_shader = $shade_picker.hasClass('js-product-shade-picker--travel-kit');

        if (kit_shader) {
          $shade_picker.trigger('kits.shadeSelect', [skuBaseId]);
        } else {
          $product.trigger('product.skuSelect', [skuBaseId]);
        }

        // Toggle the active state of the selected shades
        // $product.find('.js-product-shade').removeClass('active');
        // $(this).addClass('active');
      }
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('.js-product-shade', this).removeClass('active');
    $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', this).addClass('active');
    _setSmooshColor(skuBaseId, $(this));
    $('select.js-sku-menu', this).val(skuBaseId);
  });

  $(document).on('mouseenter', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $(this).data('sku');

    _setSmooshColor(skuId, $product);
  });

  $(document).on('mouseleave', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $product.data('sku-base-id');

    _setSmooshColor(skuId, $product);
  });
})(jQuery);
